<!-- main-layout.component.html -->
<div class="grid">
  <div class="col-12">
    <p-menubar [model]="menuItems">
      <ng-template pTemplate="start">
        <a routerLink="/" class="logo">
          <!-- Your Logo Here -->
          <img src="logo.png" alt="Logo" height="40" />
        </a>
      </ng-template>

      <ng-template pTemplate="end">
        <div class="user-actions">
          <p-button
            [icon]="
              layoutService.theme() === 'dark' ? 'pi pi-moon' : 'pi pi-sun'
            "
            (click)="layoutService.toggleTheme()"
            onkeypress=""
            class="mr-2 border-none"
            styleClass="theme-button"
            [outlined]="true"
          />

          <div class="avatar-badge-container">
            <p-avatar
              severity="success"
              size="large"
              [image]="authStore.user()?.profilePicture"
              styleClass="mr-4 ml-2 cursor-pointer "
              [icon]="authStore.user()?.profilePicture ? '' : 'pi pi-user'"
              (click)="userMenu.toggle($event)"
              onkeypress=""
              shape="circle"
            />
            <p-badge
              [styleClass]="
                authStore.user()
                  ? 'mr-3 avatar-status-badge avatar-status-online'
                  : 'mr-3 avatar-status-badge avatar-status-offline'
              "
            />
          </div>

          <p-tieredMenu #userMenu [model]="userMenuItems" [popup]="true" />
        </div>
      </ng-template>
    </p-menubar>
  </div>
</div>

<div class="grid mt-1 nested-grid">
  <div class="col-12">
    <router-outlet></router-outlet>
  </div>
</div>
