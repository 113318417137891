// main-layout.component.ts

// angular
import { Router } from '@angular/router';
import { Component, effect, inject, OnDestroy, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Auth, user, signOut, authState } from '@angular/fire/auth';
import { debounceTime, Subscription } from 'rxjs';

// prime ng
import { MenuItem, PrimeIcons } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroup, AvatarGroupModule } from 'primeng/avatargroup';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { BadgeModule } from 'primeng/badge';
import { ImageModule } from 'primeng/image';

// app
import { AuthService } from '../../services/auth.service';
import { User as AppUser, User } from '../../models/user.model';
import { LayoutService } from '../../services/layout.service';
import { AuthStore } from '../../services/auth.store';

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MenubarModule,
    SplitButtonModule,
    ButtonModule,
    AvatarModule,
    TieredMenuModule,
    BadgeModule,
    AvatarGroupModule,
    ImageModule,
  ],
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnDestroy {
  constructor(
    public readonly router: Router,
    public readonly authService: AuthService,
    public readonly layoutService: LayoutService,
    public readonly authStore: AuthStore
  ) {
    effect(() => {
      this.initMenuItems();
    });
    this.layoutService.getInitialTheme();
  }
  menuItems: MenuItem[] = [];
  userMenuItems: MenuItem[] = [];

  ngOnInit(): void {}

  initMenuItems() {
    this.menuItems = [
      { label: 'Home', icon: PrimeIcons.HOME, routerLink: '/' },
      {
        label: 'My Vehicles',
        icon: PrimeIcons.CAR,
        routerLink: '/vehicles',
        visible: this.authService.isAuthenticated(),
      },
      {
        label: 'My Projects',
        icon: PrimeIcons.BRIEFCASE,
        routerLink: '/projects',
        visible: this.authService.isAuthenticated(),
      },
      {
        label: 'Community Feed',
        icon: PrimeIcons.USERS,
        routerLink: '/community',
      },
      { label: 'About', icon: PrimeIcons.INFO_CIRCLE, routerLink: '/about' },
    ];

    this.userMenuItems = [
      {
        label: this.authStore.user()?.username,
        disabled: true,
        styleClass: '.p-menuitem-text',
        visible: this.authService.isAuthenticated(),
      },
      {
        label: 'Login',
        icon: PrimeIcons.SIGN_IN,
        command: () => this.router.navigate(['auth/login']),
        visible: !this.authService.isAuthenticated(),
      },
      {
        label: 'Register',
        icon: PrimeIcons.USER_PLUS,
        command: () => this.router.navigate(['auth/register']),
        visible: !this.authService.isAuthenticated(),
      },
      {
        separator: true,
        visible: this.authService.isAuthenticated(),
      },

      {
        label: 'Profile',
        icon: PrimeIcons.USER_EDIT,
        command: () => this.goToProfile(),
        visible: this.authService.isAuthenticated(),
      },
      {
        label: 'Logout',
        icon: PrimeIcons.SIGN_OUT,
        command: () => {
          this.logout();
        },
        visible: this.authService.isAuthenticated(),
      },
    ];
  }

  logout() {
    this.authService.userLogout();
    this.router.navigate(['/auth/login']);
  }

  // getUsername(): string {
  //   return this.currentUser?.username ?? 'Guest';
  // }
  goToProfile() {
    this.router.navigate(['user/edit-profile']);
  }
  ngOnDestroy() {
    // if (this.authStateSubscription) {
    //   this.authStateSubscription.unsubscribe();
    // }
  }
}
