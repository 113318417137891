<!-- login.component.html -->
<div class="grid nested-grid justify-content-center">
  <div class="col-0 md:col-3"></div>
  <div class="col-12 md:col-6">
    <div class="login-card">
      <p-card header="Login">
        <form [formGroup]="loginForm" (ngSubmit)="login()">
          <div class="p-fluid">
            <div class="mt-6">
              <p-floatLabel>
                <input
                  id="email"
                  type="text"
                  pInputText
                  formControlName="email"
                />
                <label for="email">Email</label>
              </p-floatLabel>
              <small
                *ngIf="
                  loginForm.get('email')?.invalid &&
                  loginForm.get('email')?.touched
                "
                class="p-error"
              >
                Please enter a valid email.
              </small>
            </div>
            <div class="p-field">
              <p-floatLabel>
                <input
                  id="password"
                  type="password"
                  formControlName="password"
                  pInputText
                />
                <label for="password">Password</label>
              </p-floatLabel>
            </div>
            <div class="p-field mt-4">
              <button
                pButton
                [loading]="loading"
                [disabled]="loading"
                label="Login"
                icon="pi pi-sign-in"
              ></button>
            </div>
            <div class="p-field mt-4">
              <p-messages [(value)]="messages" [enableService]="true" />
            </div>
            <div class="p-field">
              <p>
                Don't have an account?
                <a routerLink="/auth/register">Register here.</a>
              </p>
            </div>
            <!-- <div class="p-field">
              <p>
                Forgot password?
                <a routerLink="/auth/reset-password">Reset it here.</a>
              </p>
            </div> -->
          </div>
        </form>
      </p-card>
    </div>
  </div>
  <div class="col-0 md:col-3"></div>
</div>
