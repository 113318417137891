// username-unique.validator.ts
import {
  AbstractControl,
  ValidationErrors,
  AsyncValidatorFn,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  debounceTime,
  map,
  catchError,
  switchMap,
  exhaustMap,
} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UsernameValidator {
  constructor(private readonly http: HttpClient) {}

  checkUsernameUnique(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null); // skip validation if no username
      }
      return of(control.value).pipe(
        debounceTime(2000), // add delay to reduce API calls
        exhaustMap((username) =>
          this.http
            .get<boolean>(`/api/users/unique-username?username=${username}`)
            .pipe(
              map((isUnique) => (isUnique ? null : { usernameTaken: true })),
              catchError(() => of(null)) // ignore errors and return no validation errors
            )
        )
      );
    };
  }
}
