// angular
import { Injectable, effect, signal, computed, inject } from '@angular/core';
import { FirebaseError } from '@angular/fire/app';
import { Router } from '@angular/router';
import {
  Auth,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  UserCredential,
} from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

// prime ng
import { Message, MessageService } from 'primeng/api';

// app
import { User } from '../models/user.model';
import { RegisterUserRequest } from '../models/requests/register-user.request';
import { AuthStore } from './auth.store';
import { LocalStorageService } from './localstorage.service';

export interface AppRegisterUserRequest {
  email: string;
  password: string;
  username: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // #region fields
  private readonly auth: Auth = inject(Auth);

  //#endregion

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly messageService: MessageService,
    private readonly authStore: AuthStore,
    private readonly localStorageService: LocalStorageService
  ) {
    this.initAuthListener();
  }
  // #region state setters and getters

  isAuthenticated(): boolean {
    return this.authStore.user() !== null;
  }

  // #endregion

  // #region firebase
  private initAuthListener() {
    onAuthStateChanged(this.auth, async (firebaseUser) => {
      if (firebaseUser) {
        this.authStore.setFirebaseUser(firebaseUser);
      } else {
        this.authStore.clearFirebaseUser();
      }
    });
  }

  async firebaseLogin(
    email: string,
    password: string
  ): Promise<UserCredential | null> {
    try {
      const userCredential = await signInWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      console.log(userCredential);
      return userCredential;
    } catch (error) {
      throw error;
    }
  }

  async firebaseLogout() {
    await signOut(this.auth);
  }

  async firebaseRegister(
    email: string,
    password: string
  ): Promise<UserCredential | null> {
    try {
      const user = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      return user;
    } catch (error: any) {
      this.messageService.clear();
      let errorMessage: Message = {
        severity: 'error',
        summary: 'Error',
        detail: this.getFriendlyErrorMessage(error),
      };
      this.messageService.add(errorMessage);
      return null;
    }
  }
  // #endregion

  // #region database methods

  private async databaseGetProfile() {
    return await firstValueFrom(this.http.get<User>(`/api/users`));
  }

  // sets last login date in db and returns the user db data
  private async databaseLogin(): Promise<User> {
    return await firstValueFrom(this.http.get<User>(`/api/users/login`));
  }

  // adds user to db and returns the user
  private async databaseRegister(user: RegisterUserRequest): Promise<User> {
    return await firstValueFrom(this.http.post<User>('/api/users', user));
  }

  private async databaseUpdate(userData: Partial<User>) {
    const userRefId = this.authStore.user()?.refId;
    return await firstValueFrom(
      this.http.put<User>(`/api/users/${userRefId}/profile`, userData)
    );
  }

  private async databaseProfilePictureUpload(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return await firstValueFrom(
      this.http.post<{ profilePictureUrl: string }>(
        `/api/users/profile-picture`,
        formData
      )
    );
  }

  // #endregion

  // #region user flows

  async userLogin(email: string, password: string): Promise<User | null> {
    try {
      const userCredential = await this.firebaseLogin(email, password);
      if (!userCredential?.user) {
        return null;
      }
      this.authStore.setFirebaseUser(userCredential.user);
      const user = await this.databaseLogin();
      this.authStore.setUser(user);
      return user;
    } catch (error) {
      this.authStore.clearUser();
      throw error;
    }
  }

  async userLogout() {
    await this.firebaseLogout();
    this.authStore.clearUser();
  }

  async registerUser(user: AppRegisterUserRequest): Promise<User | null> {
    const userCredential = await this.firebaseRegister(
      user.email,
      user.password
    );
    const token = await userCredential?.user?.getIdToken();
    if (!token || !userCredential) {
      return null;
    }
    this.storeTokenInLocalStorage(token);
    // call api to create the new user
    let userRequest: RegisterUserRequest = {
      email: user.email,
      username: user.username,
      firebaseId: userCredential.user.uid,
    };
    const registeredUser = await this.databaseRegister(userRequest);
    this.messageService.clear();
    let successMessage: Message = {
      severity: 'success',
      summary: 'Success',
      detail: 'Account created successfully. Please log in.',
    };
    this.messageService.add(successMessage);
    this.firebaseLogout();
    return registeredUser;
  }

  public async updateUserProfile(userData: Partial<User>) {
    try {
      const updateResponse = await this.databaseUpdate(userData);

      this.authStore.setUser(updateResponse);
    } catch (error) {}
  }

  public async updateProfilePicture(file: File) {
    const uploadResponse = await this.databaseProfilePictureUpload(file);
    this.authStore.updateUserProfilePicture(uploadResponse.profilePictureUrl);
  }

  // #endregion

  getJwtToken() {
    return localStorage.getItem('token');
  }
  storeTokenInLocalStorage(token: string) {
    localStorage.setItem('token', token);
  }
  getFriendlyErrorMessage(error: FirebaseError) {
    const errorMessages: { [key: string]: string } = {
      'auth/email-already-in-use':
        'This email address is already in use. Please try logging in or use a different email.',
      'auth/user-not-found':
        'No account found with this email address. Please check your email or sign up.',
      'auth/wrong-password':
        'The password you entered is incorrect. Please try again.',
      'auth/invalid-credential':
        'Either the password you entered is incorrect or that account does not exist. Please try again.',
      'auth/invalid-email':
        'The email address is not valid. Please check for typos and try again.',
      'auth/weak-password':
        'The password is too weak. Please choose a stronger password.',
      'auth/network-request-failed':
        'Network error. Please check your internet connection and try again.',
      // Add more Firebase error codes as needed
    };
    const defaultMessage =
      'An unexpected error occurred. Please try again later.';
    // Extract the error code from the Firebase error object
    const errorCode = error.code || error.message.match(/\(([^)]+)\)/)?.[1];
    // Return the friendly error message or a default one
    return errorMessages[error.code] || defaultMessage;
  }
}
