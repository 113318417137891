// home.component.ts
import { Component, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';

import { Auth, User, user } from '@angular/fire/auth';
import { Subscription } from 'rxjs';

import { AuthService } from '../../services/auth.service';
import { AuthStore } from '../../services/auth.store';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, CardModule],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnDestroy {
  private readonly auth: Auth = inject(Auth);
  user$ = user(this.auth);
  userSubscription: Subscription;
  constructor(private readonly authStore: AuthStore) {
    this.userSubscription = this.user$.subscribe((aUser: User | null) => {
      //handle user state changes here. Note, that user will be null if there is no currently logged in user.
    });
  }

  isAuthenticated() {
    return this.auth.currentUser !== null;
  }
  getUserName() {
    return this.authStore.user()?.username ?? 'Guest';
  }
  ngOnDestroy() {
    // when manually subscribing to an observable remember to unsubscribe in ngOnDestroy
    this.userSubscription.unsubscribe();
  }
}
