<!-- register.component.html -->
<div class="grid nested-grid justify-content-center">
  <div class="col-0 md:col-3"></div>
  <div class="col-12 md:col-6">
    <div class="register-card">
      <p-card header="Create an Account">
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
          <div class="p-fluid">
            @if(!registered){

            <div class="p-field">
              <p-floatLabel>
                <input
                  id="email"
                  type="email"
                  pInputText
                  formControlName="email"
                />
                <label for="email">Email</label>
              </p-floatLabel>

              <small
                *ngIf="
                  registerForm.get('email')?.invalid &&
                  registerForm.get('email')?.touched
                "
                class="p-error"
              >
                Please enter a valid email.
              </small>
            </div>
            <div class="p-field">
              <p-floatLabel>
                <input
                  id="username"
                  type="username"
                  pInputText
                  formControlName="username"
                  [style]="
                    registerForm.get('username')?.invalid &&
                    registerForm.get('username')?.touched
                      ? 'box-shadow: 0 0 0.2rem rgba(250, 96, 134, 0.2);'
                      : ''
                  "
                />
                <label for="username">Username</label>
              </p-floatLabel>
              <small
                *ngIf="registerForm.get('username')?.hasError('usernameTaken')"
                class="p-error"
              >
                That username is already taken.
              </small>
            </div>
            <div class="p-field">
              <p-floatLabel>
                <p-password
                  id="password"
                  formControlName="password"
                  [feedback]="false"
                  [toggleMask]="true"
                ></p-password>
                <label for="password">Password</label>
              </p-floatLabel>

              <small
                *ngIf="
                  registerForm.get('password')?.invalid &&
                  registerForm.get('password')?.touched
                "
                class="p-error"
              >
                Password must be at least 6 characters.
              </small>
            </div>
            <div class="p-field">
              <p-floatLabel>
                <p-password
                  id="confirmPassword"
                  formControlName="confirmPassword"
                  [feedback]="false"
                  [toggleMask]="true"
                ></p-password>
                <label for="confirmPassword">Confirm Password</label>
              </p-floatLabel>

              <small
                *ngIf="
                  registerForm.hasError('mismatch') &&
                  registerForm.get('confirmPassword')?.touched
                "
                class="p-error"
              >
                Passwords do not match.
              </small>
            </div>
            <div class="p-field">
              <button
                pButton
                label="Register"
                icon="pi pi-user-plus"
                [disabled]="loading"
                [loading]="loading"
                class="mt-2"
              ></button>
            </div>
            }

            <div class="p-field">
              <p-messages [(value)]="errorMessages" [enableService]="true" />
            </div>
            <div class="p-field mt-5">
              <p>
                Already have an account?
                <a routerLink="/auth/login">Login here</a>.
              </p>
            </div>
          </div>
        </form>
      </p-card>
    </div>
  </div>
  <div class="col-0 md:col-3"></div>
</div>
