import { Environment } from './environment.interface';
export const environment: Environment = {
  apiUrl:
    'https://everrestore-api-dev-f4etd5f9b9b8hqg7.eastus-01.azurewebsites.net',
  firebaseConfig: {
    apiKey: 'AIzaSyA8btvqpfkIKpgHcti25BOzQfAdJll4Mpk',
    authDomain: 'ever-restore.firebaseapp.com',
    projectId: 'ever-restore',
    storageBucket: 'ever-restore.firebasestorage.app',
    messagingSenderId: '1067193574161',
    appId: '1:1067193574161:web:fe7f3f33f6a553cf5ba2b0',
    measurementId: 'G-DZ3KP2PWXK',
  },
};
