import { Routes } from '@angular/router';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { AuthGuard } from './guards/auth.guard';

// Import standalone components directly
import { HomeComponent } from './pages/home/home.component';
// import { VehiclesComponent } from './pages/vehicles/vehicles.component';
// import { ProjectsComponent } from './pages/projects/projects.component';
// import { CommunityComponent } from './pages/community/community.component';
// import { AboutComponent } from './pages/about/about.component';
import { AuthComponent } from './pages/auth/auth.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { UserComponent } from './pages/user/user.component';
import { EditProfileComponent } from './pages/user/edit-profile/edit-profile.component';
export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      {
        path: 'user',
        component: UserComponent,
        children: [{ path: 'edit-profile', component: EditProfileComponent }],
      },
      {
        path: 'auth',
        component: AuthComponent,
        children: [
          { path: 'login', component: LoginComponent },
          { path: 'register', component: RegisterComponent },
        ],
      },
      // { path: 'vehicles', component: VehiclesComponent, canActivate: [AuthGuard] },
      // { path: 'projects', component: ProjectsComponent, canActivate: [AuthGuard] },
      // { path: 'community', component: CommunityComponent },
      // { path: 'about', component: AboutComponent }
    ],
  },

  // { path: '**', redirectTo: '' }, // Wildcard route for a 404 page
];
