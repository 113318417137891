import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthStore } from '../services/auth.store';
import { inject } from '@angular/core';
import { from, Observable, switchMap } from 'rxjs';

export const apiPrefixInterceptor: HttpInterceptorFn = (request, next) => {
  // Check if the URL starts with '/api' and is not a full URL
  if (request.url.startsWith('/api')) {
    // Clone the request and update the URL with the base URL from environment
    const apiReq = request.clone({
      url: `${environment.apiUrl}${request.url}`,
    });
    return next(apiReq);
  }
  return next(request);
};

export const authInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const authStore = inject(AuthStore);

  const firebaseUser = authStore.firebaseUser();
  if (!firebaseUser) {
    return next(request);
  }

  // Convert the token retrieval into an observable
  return from(firebaseUser.getIdToken()).pipe(
    switchMap((token) => {
      // Check if the URL starts with '/api' and is not a full URL
      if (request.url.startsWith('/api')) {
        // Clone the request and add the Authorization header with the token
        const apiReq = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
        return next(apiReq);
      }
      return next(request);
    })
  );
};

// export const authInterceptor: HttpInterceptorFn = async (request, next) => {
//   const authStore = inject(AuthStore);

//   const userCred = authStore.firebaseUserCredential();
//   if (!userCred) {
//     return next(request);
//   }

//   const token = userCred.user.
//   // Check if the URL starts with '/api' and is not a full URL
//   if (request.url.startsWith('/api')) {
//     // Clone the request and add the Authorization header
//     const apiReq = request.clone({
//       setHeaders: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     return next(apiReq);
//   }
//   return next(request);
// };
