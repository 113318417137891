// login.component.ts
import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import {
  AbstractControlOptions,
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms';
import { CardModule } from 'primeng/card';
import { Router, RouterModule } from '@angular/router';
import { Auth, signInWithEmailAndPassword } from '@angular/fire/auth';
import { Messages, MessagesModule } from 'primeng/messages';
import { Message, MessageService } from 'primeng/api';
import { FirebaseError } from '@angular/fire/app';
import { AuthService } from '../../../services/auth.service';
import { FloatLabelModule } from 'primeng/floatlabel';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    InputTextModule,
    ButtonModule,
    CardModule,
    MessagesModule,
    RouterModule,
    FloatLabelModule,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm: FormGroup;
  messages: Message[] = [];
  loading: boolean = false;
  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly messageService: MessageService,
    public readonly authService: AuthService
  ) {
    const formOptions: AbstractControlOptions = {
      validators: [Validators.required],
      asyncValidators: [],
      updateOn: 'change',
    };
    this.loginForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
      },
      formOptions
    );
  }

  async login() {
    try {
      this.loading = true;
      const email = this.loginForm.get('email')?.value;
      const password = this.loginForm.get('password')?.value;
      await this.authService.userLogin(email, password);
      this.router.navigate(['/']);
    } catch (error: any) {
      const errorMessage = this.authService.getFriendlyErrorMessage(error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    } finally {
      this.loading = false;
    }
  }
}
