// angular
import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  // #region fields

  private readonly themeSignal = signal<'dark' | 'light'>('dark');
  public readonly theme = this.themeSignal.asReadonly();

  //#endregion

  constructor() {}

  public toggleTheme() {
    this.setTheme(this.theme() === 'dark' ? 'light' : 'dark');
  }
  public setTheme(theme: 'dark' | 'light') {
    this.themeSignal.set(theme);
    localStorage.setItem('theme', theme);
    this.changeCssFile(theme);
  }

  public getInitialTheme() {
    const theme = localStorage.getItem('theme');
    if (theme) {
      this.setTheme(theme as 'dark' | 'light');
    }
  }

  private changeCssFile(theme: 'dark' | 'light') {
    // find the link element with id theme-link
    const element = document.getElementById('theme-file');
    // set the href attribute to the new theme css file
    if (element) {
      element.setAttribute('href', `theme-${theme}.css`);
    }
  }
}
