// angular
import { Injectable, signal, inject } from '@angular/core';
import { FirebaseError } from '@angular/fire/app';
import { Router } from '@angular/router';
import { User } from '../models/user.model';
import { User as FirebaseUser } from '@angular/fire/auth';
import { UserCredential } from '@angular/fire/auth';
import { LocalStorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthStore {
  constructor(private readonly localStorageService: LocalStorageService) {}
  // #region user profile data
  private readonly userSignal = signal<User | null>(null);
  public readonly user = this.userSignal.asReadonly();

  loadInitialProfileData() {
    const data = this.localStorageService.getItem('userProfile');
    if (data) {
      const userData = JSON.parse(data);
      if (userData?.username) {
        this.setUser(userData);
      }
    }
  }

  setUser(user: User) {
    this.userSignal.set(user);
    const userString = JSON.stringify(user);
    this.localStorageService.setItem('userProfile', userString);
  }

  clearUser() {
    this.userSignal.set(null);
    this.localStorageService.removeItem('userProfile');
  }
  updateUserProfilePicture(profilePictureUrl: string) {
    const user = this.user();
    if (user) {
      user.profilePicture = profilePictureUrl;
      this.setUser(user);
    }
  }
  // #endregion

  // #region firebase user data
  private readonly firebaseUserSignal = signal<FirebaseUser | null>(null);
  public readonly firebaseUser = this.firebaseUserSignal.asReadonly();

  setFirebaseUser(firebaseUser: FirebaseUser) {
    this.firebaseUserSignal.set(firebaseUser);
  }
  clearFirebaseUser() {
    this.firebaseUserSignal.set(null);
  }
  // #endregion
}
