<div class="grid nested-grid justify-content-center">
  <div class="col-12">
    <p-card header="Edit Profile">
      <div class="grid">
        <div class="col-12">
          <form (ngSubmit)="onSubmit()">
            <div class="grid justify-content-center">
              <div class="col-12">
                <label for="profilePicture">Profile Picture</label>
              </div>
            </div>
            <div class="grid justify-content-center">
              <div class="col-12 text-center">
                <div class="relative">
                  <img
                    *ngIf="authStore.user()?.profilePicture"
                    [src]="authStore.user()?.profilePicture"
                    [class.loading]="uploadLoading"
                    alt="Profile Picture"
                    class="profile-picture mx-auto"
                  />
                  <p-progressSpinner
                    *ngIf="uploadLoading"
                    styleClass="overlay-spinner"
                  ></p-progressSpinner>
                </div>
              </div>
              <div class="col-12 text-center">
                <div>
                  <p-fileUpload
                    #fileUpload
                    [disabled]="uploadLoading"
                    mode="basic"
                    name="profilePicture"
                    accept="image/*"
                    chooseIcon="pi pi-camera"
                    [showUploadButton]="false"
                    [customUpload]="true"
                    (onSelect)="onProfilePictureUpload($event)"
                    [chooseLabel]="
                      !authStore.user()?.profilePicture ? 'Choose' : 'Change'
                    "
                    [maxFileSize]="10000000"
                  ></p-fileUpload>
                </div>
              </div>
              <div class="col-12 text-center">
                <p-messages
                  key="profile-picture"
                  [(value)]="messages"
                ></p-messages>
              </div>
            </div>

            <div class="grid mt-6 p-field">
              <div class="col-12">
                <label for="email">Email</label>
              </div>
              <div class="col-12 text-center md:text-left">
                <input
                  id="email"
                  type="email"
                  pInputText
                  [readonly]="true"
                  [disabled]="true"
                  [value]="authStore.user()?.email"
                />
              </div>
            </div>
            <div class="grid p-field mt-2">
              <div class="col-12">
                <label for="email">Username</label>
              </div>
              <div class="col-12 text-center md:text-left">
                <input
                  id="username"
                  type="text"
                  pInputText
                  [readonly]="true"
                  [disabled]="true"
                  [value]="authStore.user()?.username"
                />
              </div>
            </div>
            <div class="grid mt-4 p-field">
              <div class="col-12">
                <label for="bio">Bio</label>
              </div>
              <div class="col-12 text-center">
                <p-editor
                  [(ngModel)]="userBio"
                  name="bio"
                  [style]="{ height: '200px' }"
                >
                  <ng-template pTemplate="header">
                    <span class="ql-formats">
                      <button
                        type="button"
                        class="ql-bold"
                        aria-label="Bold"
                      ></button>
                      <button
                        type="button"
                        class="ql-italic"
                        aria-label="Italic"
                      ></button>
                      <button
                        type="button"
                        class="ql-underline"
                        aria-label="Underline"
                      ></button>
                    </span>
                  </ng-template>
                </p-editor>
              </div>
            </div>
            <div class="grid mt-4 p-field">
              <div class="col-12 text-center">
                <button
                  pButton
                  type="submit"
                  label="Save Changes"
                  [disabled]="loading"
                  [loading]="loading"
                  icon="pi pi-save"
                ></button>
              </div>
            </div>
          </form>
          <p-messages [(value)]="messages"></p-messages>
        </div>
      </div>
    </p-card>
  </div>
</div>
