// angular
import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormBuilder,
  FormGroup,
  AbstractControlOptions,
} from '@angular/forms';
import {
  Auth,
  createUserWithEmailAndPassword,
  signOut,
} from '@angular/fire/auth';
import { RouterModule } from '@angular/router';
import { FirebaseError } from 'firebase/app';

// primeng
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { MessagesModule } from 'primeng/messages';
import { Message, MessageService } from 'primeng/api';
import { FloatLabelModule } from 'primeng/floatlabel';
import { IconField, IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';

// app
import {
  AppRegisterUserRequest,
  AuthService,
} from '../../../services/auth.service';
import { UsernameValidator } from '../../../validators/username-unique.validator';
import { RegisterUserRequest } from '../../../models/requests/register-user.request';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    CardModule,
    RouterModule,
    MessagesModule,
    FloatLabelModule,
    IconFieldModule,
    InputIconModule,
  ],
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  private readonly auth: Auth = inject(Auth);
  registerForm: FormGroup;
  loading: boolean = false;
  registered: boolean = false;
  errorMessages: Message[] = [];

  constructor(
    private readonly fb: FormBuilder,
    private readonly messageService: MessageService,
    private readonly authService: AuthService,
    private readonly usernameValidator: UsernameValidator
  ) {
    const formOptions: AbstractControlOptions = {
      validators: [Validators.required],
      asyncValidators: [],
      updateOn: 'change',
    };

    this.registerForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required]],
        username: [
          '',
          [Validators.required],
          [this.usernameValidator.checkUsernameUnique()],
        ],
      },
      formOptions
    );
  }

  /** Custom validator to check if passwords match */
  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password')?.value;
    const confirmPassword = formGroup.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { mismatch: true };
  }

  /** Register a new user */
  async onSubmit() {
    if (this.registerForm.valid) {
      this.loading = true;
      const registerUserRequest: AppRegisterUserRequest = {
        email: this.registerForm.get('email')?.value,
        password: this.registerForm.get('password')?.value,
        username: this.registerForm.get('username')?.value,
      };
      const registeredUser = await this.authService.registerUser(
        registerUserRequest
      );
      this.loading = false;
      this.registered = true;
    }
  }
}
