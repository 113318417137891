// angular
import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { setPersistence, browserLocalPersistence } from 'firebase/auth';

import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideRouter } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';

// prime ng
import { MessageService } from 'primeng/api';

// app
import { routes } from './app.routes';
import { environment } from '../environments/environment';
import {
  apiPrefixInterceptor,
  authInterceptor,
} from './middleware/http-interceptors';

export const appConfig: ApplicationConfig = {
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => {
      const auth = getAuth();
      setPersistence(auth, browserLocalPersistence);
      return auth;
    }),
    provideHttpClient(
      withInterceptors([authInterceptor, apiPrefixInterceptor])
    ),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    importProvidersFrom(BrowserAnimationsModule),
    MessageService,
  ],
};
