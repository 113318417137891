// edit-profile.component.ts

// angular
import { Component, effect, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';

// prime ng
import { InputTextModule } from 'primeng/inputtext';
import { EditorModule } from 'primeng/editor';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { Message } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessageService } from 'primeng/api';

// app
import { AuthService } from '../../../services/auth.service';
import { AuthStore } from '../../../services/auth.store';

@Component({
  selector: 'app-edit-profile',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    EditorModule,
    FileUploadModule,
    ButtonModule,
    MessageModule,
    RouterModule,
    CardModule,
    ProgressSpinnerModule,
  ],
  templateUrl: './edit-profile.component.html',
  styleUrl: './edit-profile.component.scss',
})
export class EditProfileComponent {
  // ViewChild for file upload component
  @ViewChild('fileUpload') fileUpload!: FileUpload;
  messages: Message[] = [];
  loading: boolean = false;
  uploadLoading: boolean = false;
  userBio: string = '';

  constructor(
    private readonly fb: FormBuilder,
    public readonly authService: AuthService,
    public readonly authStore: AuthStore,
    private readonly messageService: MessageService
  ) {
    this.userBio = this.authStore.user()?.bio ?? '';
    effect(() => {});
  }

  /** Handle profile picture upload */
  async onProfilePictureUpload(event: any) {
    this.uploadLoading = true;
    console.log(event);
    try {
      const file = event.files[0];
      console.log('File:', file);
      if (file) {
        console.log(file);
        this.loading = true;

        await this.authService.updateProfilePicture(file);
        this.messages = [
          {
            severity: 'success',
            summary: 'Success',
            detail: 'Profile picture updated.',
          },
        ];
      }
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      this.messages = [
        {
          key: 'profile-picture',
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to upload profile picture.',
        },
      ];
    } finally {
      this.loading = false;
      this.uploadLoading = false;
      this.fileUpload.clear();
    }
  }

  /** Submit the edited profile */
  async onSubmit() {
    this.loading = true;
    try {
      const user = this.authStore.user();
      if (!user?.refId) {
        return;
      }
      const bio = this.userBio;
      await this.authService.updateUserProfile({ bio });
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Profile updated.',
      });
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to update profile.',
      });
    } finally {
      this.loading = false;
    }
  }
}
